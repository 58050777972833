@import url('https://spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css');

.announcement {
    font-family: 'Spoqa Han Sans Neo';
    width: 100%;
    height: 80px;
    z-index: 15;
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-between;
    color: white;
}

.desc {
    padding-top: 25px;
    padding-left: 40px;
    font-weight: 700;
    padding-right: 40px;;
}

.button {
    cursor: pointer;
}

@media only screen and (max-width: 820px) {
    .announcement {
        height: max-content;
    }

    .desc {
        padding-bottom: 25px;
    }
}