.splitHr {
    width: 100%;
    left: 0;
    margin-bottom: 3%;
    background-color: rgb(230, 230, 230);
}

.supportAll {
    display: flex;
    margin-top: 5%;
    margin-left: 7%;
    margin-right: 7%;
    margin-bottom: 3%;
}

.statusBox {
    width: 100%;
    height: max-content;
}

.statusTitle {
    font-weight: 700;
    font-size: 25px;
    letter-spacing: -2px;
    margin-top: 5%;
}

.statusTotal {
    width: 90%;
}

.statusArticle {
    width: 100%;
    height: 70px;
    background-color: rgb(250, 250, 250);
    border-radius: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    transition: 0.2s all ease;
}

.statusArticle:hover {
    cursor: pointer;
    transform: scale(1.03);
    transition: 0.3s all ease;
}

.statusCol {
    padding: 25px;
    display: flex;
}

.statusContent {
    color: black;
    font-weight: 400;
    margin-left: 10px;
    margin-top: -5px;
}

.stRed {
    width: 13px;
    height: 13px;
    background-color: red;
    border-radius: 50px;
}

.stGreen {
    width: 13px;
    height: 13px;
    background-color: lime;
    border-radius: 50px;
}

.stOrange {
    width: 13px;
    height: 13px;
    background-color: orange;
    border-radius: 50px;
}

.issueBox {
    width: 100%;
    height: max-content;
}

.issueContent {
    width: 90%;
    height: 195px;
    background-color: rgb(250, 250, 250);
    border-radius: 15px;
    padding: 25px;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow:auto;
    white-space: normal;
    margin-top: 10px;
    font-weight: 400;
    color: black;
}

.issueArticle {
    height: 155px;
}

.statusList {
    display: flex;
}

.issueBar {
    position: relative;
    bottom: 0;
}

.statusListChild {
    display: flex;
    margin-right: 15px;
    justify-content: space-between;
}

.childName {
    margin-top: -5px;
    font-size: 13px;
    font-weight: 300;
}

.Mini {
    width: 10px;
    height: 10px;
    margin-right: 3px;
}

.supportInput {
    margin-top: 1%;
    margin-bottom: 1%;
    width: 50%;
    padding: 10px 10px 10px 0;
    border: none;
    border-bottom: solid 3px black;
    outline: none;
    letter-spacing: -1px;
    font-weight: 700;
    font-size: 20px;
    font-family: 'Jost', 'Spoqa Han Sans Neo', 'Noto Sans KR', 'sans-serif';
}

.supportInput::placeholder{
    color: lightgrey;
}

.supportQues {
    width: 100%;
}

.otherQues {
    width: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.oqTitle {
    text-align: center;
    font-weight: 700;
    font-size: 25px;
    letter-spacing: -2px;
    margin-top: 5%;
}

.oqDesc {
    text-align: center;
    font-weight: 400;
    margin: 5px;
}

.radiusButton {
    margin: 15px auto;
    background-color: black;
    width: max-content;
    padding: 10px 20px 10px 20px;
    color: white;
    border-radius: 50px;
    border: solid 1px black;
    font-weight: 400;
    cursor: pointer;
    transition: 0.3s all ease;
    text-align: center;
}

.radiusButton:hover {
    background-color: rgb(250, 250, 250);
    border: solid 1px lightgrey;
    color: black;
    transition: 0.3s all ease;
}

.quesBox {
    background-color: rgb(248, 248, 248);
    border-radius: 15px;
    padding: 15px;
    margin: 1% 0;
    width: 50%;
}

.quesTitle {
    font-weight: 700;
    cursor: pointer;
}

.quesDesc {
    display: none;
    font-weight: 400;
}

.quesOpen {
    display: block;
}

@media only screen and (max-width: 768px) {

    .supportAll {
        display: block;
        margin-top: 5vh;
    }

    .statusTotal {
        width: 100%;
    }

    .issueBox {
        margin-top: 5vh;
        padding: 0;
    }

    .issueContent {
        padding: 20px;
    }

    .supportInput {
        width: 95%;
    }

    .splitHr {
        margin-top: 10vh;
        margin-bottom: 10vh;
    }

    .otherQues {
        margin-bottom: 15vh;
    }
}