.active {
    color: tomato;
    transition: all 0.2s ease;
}

.newsSide {
    font-size: 40px;
    font-weight: 900;
    height: max-content;
    z-index: -1;
    word-wrap: normal;
    letter-spacing: -5px;
    display: flex;
    justify-content:flex-start;
    margin: 0.5em 2em;
    align-items: center;
}

.newsSide > span > svg {
    cursor: pointer;
}

.newsContent {
    border: 1px solid rgb(0, 0, 0, 0.1);
    border-radius: 20px;
    background-color: rgb(246, 246, 246);
    display: flex;
    justify-content: flex-start;
    width: auto;
    align-items: center;
    margin: 0 5em;
    padding: 25px 0;
    overflow-x: scroll;
    color: black;
}

.newsContent > a {
    border-right: 1px solid lightgray;
    height: fit-content;
}

.newsInfo {
    margin: 70px 90px;
    padding-right: 20%;
    font-weight: 400;
}

.newsTitle {
    letter-spacing: -2px;
    font-weight: 700;
    font-size: 40px;
}

.newsDate {
    font-size: 20px;
    margin: 10px 0;
}

.newsDesc {
    width: 100%;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    word-break: keep-all;
}

.NewsContents {
    padding: 0 24px;
    letter-spacing: -0.5px;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 15px;
    background: linear-gradient(160deg, rgba(255, 120, 48, 0), rgba(252, 91, 83, 0));
}

.NewsContents > p {
    font-size: 12px;
    color: gray;
}

.NewsContents_Box {
    margin: 0 10px;
    white-space: nowrap;
}

.NewsContents:hover {
    color: lightcoral;
    transition: all 0.2s ease;
}

.newsImg {
    width: 70%;
    margin: 20px 0;
    border-radius: 15px;
    overflow: hidden;
}

.newsImg > img {
    width: 100%;
}

.block {
    display: block;
}

.none {
    display: none;
}

@media only screen and (max-width: 820px) {

    .newsContent {
        width: auto;
        padding: 1em;
        margin: 5vh 5vw;
        display: flex;
        justify-content: flex-start;
    }

    .NewsContents_Box {
        white-space:nowrap;
    }

    .newsSide {
        width: auto;
        padding: 1em;
        text-align: center;
        font-size: 3em;
        transform: translateY(5vh) translateZ(0px);
        background: none;
        margin: 0 auto;
        justify-content: center;
    }

    .newsSide > div {
        padding-left: 0vh;
    }

    .newsInfo {
        margin: 5em 2em;
        width: auto;
        padding: 0;
    }

    .newsDesc {
        width: 100%;
    }

    .newsImg {
        width: 100%;
    }
}