hr {
    border: 0px;
    height: 1px;
    background-color: lightgrey;
}

a {
    text-decoration: none;
    color: inherit;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:focus {
    text-decoration: none;
}

p {
    margin: 0;
}

.homeBody {
    padding-top: 2.5em;
}

button {
    border: 0;
    border-radius: 0;
    outline: 0;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
}

.uppertitle {
    top: 0;
    position: relative;
    width: auto;
    margin: 0 2em;
}

.MainSection {
    height: 40vw;
}

.maintitle {
    position: absolute;
    width: 100%;
    color: white;
    font-size: 50px;
    text-align: center;
    letter-spacing: -1px;
    font-weight: 700;
    top: 50%;
    filter: drop-shadow(0 0 0.7rem black);
    z-index: 0;
}

.NewMainTitle {
    position: absolute;
    color: white;
    letter-spacing: -2px;
    font-weight: 900;
    width: auto;
    font-size: 60px;
    top: 40%;
    filter: drop-shadow(0 0 2rem black);
}

.ContentsSection {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 80px;
    margin-bottom: 160px;
    background-color: white;
}

.HNewsTap {
    width: 450px;
    height: 350px;
    border-radius: 30px;
    padding: 2.5em;
    color: white;
    filter: none;
    transition: all 0.3s ease;
}

.HNewsTap:hover {
    filter: drop-shadow(0 0 0.7rem rgb(0,0,0, .35));
    transform: translateY(-10px);
    transition: all 0.3s ease;
}

.peach {
    background: linear-gradient(160deg, rgb(255, 121, 48), rgb(252, 91, 83));
}

.sea {
    background: linear-gradient(160deg, rgb(48, 193, 255), rgb(83, 83, 252));
}

.HNewHome {
    width: 900px;
    height: 500px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    background: url(../../img/contradict_background.png);
    background-position: center;
    background-size: cover;
}

.HomeTop {
    display: flex;
    justify-content: center;
    height: max-content;
    width: 900px;
    margin: 0 auto;
    gap: 20px;
    margin-top: 30px;
}

.HNewsTapContents {
    display: flex;
    justify-content: space-between;
}

.HNewsTap > div > p {
    width: max-content;
    font-weight: 900;
    font-size: 40px;
    letter-spacing: -2px;
    clear:left;
}

.HNewsContentsInfo {
    display: flex;
    color: white;
}

.HNewsDate {
    font-size: 15px;
    margin-top: 10px;
    color: white;
    font-weight: 400;
}

.HNewsday {
    font-size: 11px;
    margin-top: 6px;
    color: white;
    font-weight: 400;
}

.HNewsMore {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: -0.5px;
    position: relative;
    bottom: 0;
    width: auto;
    text-align: right;
    color: white;
}

.AHNewsMore {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: -0.5px;
    position: relative;
    bottom: 0;
    width: auto;
    text-align: right;
    color: white;
}

.HNewsContents_Box {
    width: 100%;
}

.HNewsContents {
    width: 90%;
    padding: 50px 0;
    letter-spacing: -1px;
    font-weight: 600;
    font-size: 30px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    display: block;
    clear:both;
    overflow: hidden;
    word-break: keep-all;
    height: 180px;
}

.flexcontent {
    display: flex;
}

.closeBtn {
    cursor: pointer;
    width: max-content;
}

.modalImg {
    width: 30vw;
    height: 30vw;
    overflow: hidden;
    object-fit: cover;
    border-radius: 20px;

}

.modalImg > img {
    width: 100%;
}

.PHNewHome {
    width: auto;
    height: 500px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 2.5em;
    margin: 0 7.5em;
    background: url(../../img/honey_moon.png);
    background-position: center;
    background-size: cover;
}

.DHNewHome {
    width: auto;
    height: 500px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 2.5em;
    margin: 0 7.5em;
    background: url(../../img/discobg.png);
    background-position: center;
    background-size: contain;
}

.ggabiArchive {
    width: 900px;
    height: auto;
    border-radius: 30px;
    background: linear-gradient(160deg, rgb(255, 162, 55), rgb(255, 208, 40));
    transition: all 0.2s ease;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.ggabiArchive > div {
    padding: 20px 50px;
}

.gaTitle {
    display: flex;
    align-items: center;
}

.gaTitle > img {
    width: 150px;
}

.gaTitle > span {
    color: white;
    font-weight: 700;
    font-size: 40px;
}

.gaDesc {
    color: white;
    font-weight: 400;
    font-size: 40px;
    padding: 20px;
    opacity: 0.2;
    transition: all 0.2s ease;
}

.ggabiArchive:hover {
    filter: drop-shadow(0 0 0.7rem rgb(0,0,0, .35));
    transform: translateY(-10px);
    transition: all 0.3s ease;
}

.ggabiArchive:hover > .gaDesc {
    opacity: 1;
    transition: all 0.7s ease;
}

.AHNewsMore {
    font-size: 25px;
    font-weight: 300;
    letter-spacing: -0.5px;
    position: relative;
    bottom: 0;
    width: auto;
    text-align: right;
    color: white;
}

.youtubeapi {
    width: 100%;
    height: 350px;
    border-radius: 30px;
    overflow: hidden;
    transition: all 0.3s ease;
}

.youtubeapi:hover {
    filter: drop-shadow(0 0 0.7rem rgb(0,0,0, .35));
    transform: translateY(-10px);
    transition: all 0.3s ease;
}

@media only screen and (max-width: 820px) {
    .HNewHome, .ggabiArchive {
        width: 650px;
    }
}

@media only screen and (max-width: 768px) {

    .homeBody {
        width: 100%;
    }

    .MainSection {
        height: 90vh;
    }

    .uppertitle {
        margin:  0 1em;
    }

    .HNewHome {
        width: auto;
        margin: 1em;
    }

    .PHNewHome {
        margin: .5em;
        word-break: keep-all;
    }

    .HNewsTap {
        width: auto;
        height: max-content;
        margin: 1em;
    }

    .NewMainTitle {
        font-size: 50px;
        width: 300px;
        text-align: center;
    }

    .HomeTop {
        display: block;
        margin: 15px 1em;
        width: auto;
    }

    .NewsTapContents {
        display: flex;
        justify-content: space-between;
    }

    .NewsTap {
        width: 75%;
        margin: 14%;
    }

    .roundbox {
        width: 100%;
        margin-bottom: 5vh;
    }

    .flexcontent {
        display: block;
    }

    .ggabiArchive {
        width: auto;
        height: max-content;
        margin: 1em;
        word-break: keep-all;
    }

    .gaTitle > img {
        width: 17vw;
    }

    .gaTitle > span {
        white-space: nowrap;
        font-size: 7vw;
    }

    .youtubeapi {
        width: auto;
        margin: 1em;
    }
}