@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;400;700;900&display=swap');

a {
    text-decoration: none;
}

.header {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    top: 0;
    z-index: 10;
    position: sticky;
    transition: all 0.2s ease-in-out;
    font-family: 'Spoqa Han Sans Neo';
    color: inherit;
    border-bottom: 1px solid rgb(0, 0, 0, 0.1);
}

.backdrop {
    backdrop-filter: saturate(180%) blur(10px);
    background-color: rgba(255,255,255,0.15);
    width: 100%;
    height: 100px;
    position: absolute;
    overflow: hidden;
    z-index: -5;
}

.active {
    color: inherit;
}

.header.scroll {
    top: -80px;
}

.header > .HeaderArticle_1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 50px 0 50px;
    align-items: center;
    z-index: 5;
    color: inherit;
    height: 100px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.1);
}

.header > .HeaderArticle_1 > div {
    width: 130px;
    padding-right: 15px;
}

.header > .HeaderArticle_1 > ul {
    list-style: none;
    display: flex;
    margin-left: -10px;
    color: inherit;
    align-items: center;
}

.header > .HeaderArticle_1 > ul > li {
    padding-right: 12px;
    letter-spacing: -1px;
    font-weight: 700;
}

.header > .HeaderArticle_1 > ul > li > div {
    color: auto;
    transition: all 0.1s ease;
}

.header > .HeaderArticle_1 > ul > li > div:hover {
    color: tomato !important;
    transition: all 0.2s ease;
}
    
.mobileMenu {
    width: 100%;
    height: 80px;
    position: fixed;
    z-index: 5;
    display: flex;
    justify-content: space-around;
    bottom: 0;
    border: 1px solid white;
    align-items: center;
    background-color: rgb(239, 239, 239);
    color: black;
    display: none;
}

.desktopMode {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 80px;
    font-size: 30px;
    cursor: pointer;
}

.mobileIcon {
    font-size: 1.5em;
    height: max-content;
}

.mobileTitle {
    font-size: .75em;
}

.mobileMenu > a > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70px;
}

@media only screen and (max-width: 768px) {
    .header {
        height: 80px;
    }

    .backdrop {
        height: 80px;
    }

    .header > .HeaderArticle_1 {
        margin-left: 20px;
        height: 80px;
    }

    .header > .HeaderArticle_1 > ul {
        display: none;
    }

    .header > .HeaderArticle_1 {
        padding: 0;
    }

    .mobileMenu {
        display: flex;
    }

    .desktopMode {
        display: none;
    }
}