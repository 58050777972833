hr {
    margin-top: 20px;
    margin-bottom: 20px;
}

.discoMenu {
    display: flex;
    margin: 0 100px;
    max-height: 65vh;
}

.discoBox {
    font-weight: 400;
    margin: 50px 0;
    width: auto;
    height: auto;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
}

.discoInfo {
    padding: 10px 20px;
    color: inherit;
    height: max-content;
}

.discoTitle {
    margin-bottom: 5px;
    font-size: 17px;
}

.discoImg {
    width: 200px;
    overflow: hidden;
    background-color: black;
}

.discoImg > img {
    width: 200px;
    display: block;
}

.newsdiscoContent {
    justify-content: center;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    height: 75%;
    left: 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
    background-color: inherit;
    position: absolute;
    padding-left: 5em;
}

.newsdiscoContent::-webkit-scrollbar {
    display: none;
}

.newsdiscoContentDetail {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    flex-wrap: wrap;
    overflow-x: hidden;
    height: 100%;
    margin: 0 auto;
    width: 50%;
}

.type {
    color: gray;
    margin-right: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.discoCate{
    display: flex;
    margin-right: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 5px;
    margin-bottom: 5px;
    color: inherit;
}

.discoDesc {
    display: flex;
    font-size: 12px;
}

.aboutDisco {
    width: 100%;
    display: flex;
}

.bigImg {
    width: 95%;
    border-radius: 20px;
    overflow: hidden;
    margin: 0 auto;
}

.bigImg > img {
    width: 100%;
    margin: 0 auto;
    display: block;
}

.thisDisco{
    width: 100%;
    padding: 3%;
}

.discoBigTitle {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: space-around;
}

.discoBigTitle > svg {
    cursor: pointer;
    position: absolute;
    left: 1em;
}

.aboutTrack {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    text-align: center;
}

.thisDiscoTitle {
    font-weight: 700;
    font-size: 30px;
    letter-spacing: -1.5px;
}

.thisDiscoDate {
    font-weight: 400;
    color: rgb(167, 167, 167);
    font-size: 14px;
}

.discoCredit {
    font-weight: 400;
    font-size: 12px;
    color: rgb(167, 167, 167);
}

.New {
    display: block;
    right: 0;
    width: 50%;
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: absolute;
    height: 75%;
    overflow: hidden;
    background-color: inherit;
    border-left: 1px solid lightgray;
}

.New::-webkit-scrollbar {
    display: none;
}

.newsFlex {
    display: block;
}

.trackNum {
    display: flex;
    flex-direction: column;
}

.thisDiscoNotice {
    text-align: left;
    top: 5%;
    position: relative;
    font-weight: 700;
}

@media only screen and (max-width: 820px) {
    .newsdiscoContent{
        flex-direction: column;
    }

    .discoBox {
        width: auto;
        height: auto;
        margin-top: 50px;
        margin-right: 0;
        margin-left: 0;
    }

    .aboutDisco {
        width: auto;
        flex-direction: column;
    }

    .bigImg, .thisDisco {
        width: auto;
    }

    .bigImg {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 768px) {

    .newsFlex {
        width: auto;
    }

    .discoMenu {
        display: block;
        margin: 0 1em;
        height: auto;
        overflow-y: visible;
        max-height: min-content;
    }
    .newsdiscoContent {
        justify-content: center;
        padding: 1em;
        margin-top: 0;
        width: auto;
        position: static;
        height: 250px;
        overflow-y: scroll;
    }

    .discoBox {
        width: 100%;
        font-weight: 400;
        margin-right: 0;
        margin-bottom: 7vh;
        align-items: center;
    }

    .discoInfo {
        width: 50%;
    }

    .discoNaviAll {
        display: none;
    }

    .discoImg {
        width: 50%;
    }

    .discoImg > img {
        width: 100%;
        object-fit: cover;
        display: block;
    }

    .aboutDisco{
        display: block;
    }

    .bigImg {
        width: auto;
    }

    .thisDisco{
        width: auto;
        padding: 0;
        margin-top: 3vh;
        margin-bottom: 3vh;
    }

    .aboutDisco {
        width: auto;
    }

    .New {
        width: auto;
        min-height: 100vh;
        position: static;
        border: none;
        min-height: max-content;
        margin-top: 5em;
        border-top: 1px solid lightgray;
    }

    .newsdiscoContentDetail {
        margin: 1em;
        width: auto;
        display: flex;
        flex-direction: row;
    }

}