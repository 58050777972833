.footer {
    width: 100%;
    height: 100px;
    bottom: 0;
    margin-top: 50px;
    text-align: center;
    font-weight: 300;
    letter-spacing: -0.5px;
    justify-content: space-evenly;
    font-family: 'Jost';
    border-top: 1px solid rgb(0, 0, 0, 0.1);
}

.fc {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    z-index: 5;
}

.mode {
    cursor: pointer;
    color: inherit;
    font-size: 30px;
    z-index: 10;
    right: 0;
    top: 0;
    display: none;
}

@media only screen and (max-width: 768px) {
    .footer {
        margin-bottom: 80px;
    }

    .mode {
        display: block;
        position: fixed;
        margin-right: 20px;
        line-height: 100px;
        font-size: 2.5em;
        height: fit-content;
    }
}