.downloadPage {
    height: 2500px;
    width: 100%;
    overflow-y: hidden;
}

.bannerArea {
    background-color: rgb(250, 250, 250);
    width: 100%;
    height: 600px;
    overflow-x: scroll;
    overflow-y: hidden;
}

.bannerArea::-webkit-scrollbar {
    display: none;
}

.bannerTitle {
    padding: 50px;
    font-weight: 700;
    font-size: 30px;
    position: absolute;
    letter-spacing: -2px;
}

.bannerBox {
    width: 550px;
    height: 380px;
    border-radius: 30px;
    overflow: hidden;
    text-align: center;
    align-items: center;
    cursor: pointer;
}

.lightpink {
    background-color: rgb(238, 218, 238);
}

.skyblue {
    background-color: rgb(185, 218, 255);
}

.bannerImg > img {
    width: 70%;
    margin: auto;
}

.bannerText {
    backdrop-filter: blur(10px);
    background-color: rgb(250, 250, 250, 0.5);
    width: 550px;
    height: 50px;
    font-weight: 400;
    position: relative;
    top: -80px;
    overflow: hidden;
    padding-top: 20px;
    font-size: 20px;
    transition: all 0.5s ease;
}

.bannerBox:hover > .bannerText {
    background-color: none;
    backdrop-filter: blur(10px);
    color: black;
    height: 380px;
    top: -400px;
    padding-top: 175px;
    transition: all 0.5s ease;
    font-weight: 700;
    font-size: 50px;
}

.list {
    list-style: none;
    display: flex;
    padding-top: 110px;
    padding-right: 25px;
}

.list > li {
    margin: 25px;
}

.DSBox {
    padding: 150px;
    padding-top: 100px;
    align-self: flex-start;
    height: max-content;
    width: 50%;
}

.DSBTitle {
    font-weight: 700;
    font-size: 35px;
    margin-bottom: 25px;
}

.DCBox {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.DetailImg {
    width: 100px;
    height: 100px;
}

.DetailImg > img {
    height: 100%;
}

.DetailContainer {
    margin-left: 25px;
}

.DetailTitle {
    font-weight: 700;
    font-size: 25px;
    letter-spacing: -2px;
}

.DetailDesc {
    font-weight: 400;
    white-space: pre-line;
}

.DetailHr {
    margin-top: 50px;
    margin-bottom: 50px;
    width: auto;
}

.SideTitle {
    font-size: 6.5vw;
    position: absolute;
    right: 150px;
    top: 800px;
}

.fixed {
    position: fixed;
    top: 100px;
}

.bannerNav {
    position: absolute;
    font-weight: 400;
    width: 100%;
    justify-content:space-between;
    height: 400px;
    align-items: center;
    z-index: 10;
    display: none;
}

.left {
    transform: translateX(0);
}

.pcScroll {
    display: block;
}

@media only screen and (max-width: 768px) {

    .downloadPage {
        width: 100%;
        height: max-content;
    }

    .bannerArea {
        height: 400px;
    }

    .bannerBox {
        width: 300px;
        height: 200px;
    }

    .bannerTitle {
        font-size: 7vw;
    }

    .list {
        margin-left: -1vw;
        display: flex;
    }

    .bannerText {
        width: 300px;
        padding-top: 15px;
    }

    .bannerBox:hover > .bannerText {
        top: -310px;
        padding-top: 175px;
        transition: all 0.5s ease;
    }

    .DSBox {
        padding: 15vw;
        padding-top: 100px;
        height: max-content;
        width: 70%;
    }

    .DCBox {
        display: flex;
        align-items:stretch;
        margin-bottom: 5vh;
        width: 100%;
        height: max-content;
    }

    .SideTitle {
        position: static;
        font-size: 19vw;
        text-align: center;
        margin-bottom: 50px;
    }

    .bannerNav {
        display: flex;
    }

    .pcScroll {
        display: none;
    }
}